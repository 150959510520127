@import "~theme/variables";

.container {
  width: 5.7rem;
  // Cannot overwrite react-slick classes. This will replace height 100% in card-header-image/index.module.scss
  height: .8rem !important;
  overflow: hidden;
  display: block;
  padding: 0;
  position: absolute;
  left: calc(50% - 2.75rem);
  height: 0.8rem;
  margin-bottom: 0.9rem;
  bottom: 0;
}

.dotsList {
  padding: 0;
  display: flex;
  transition: all 0.25s;
  position: relative;
  margin: 0;
  list-style: none;
  transform: translateX(0);
  align-items: center;
  bottom: unset;
  height: 100%;
}

.dot {
  width: .4rem;
  height: .4rem;
  border-radius: $border-radius-03;
  background-color: $color-neutral-06;
  opacity: 0.6;
  margin: $space-xs $space-xxs;
  transition: transform $transition-01;

  &.slick-active {
    transform: scale(1.5);
    opacity: 1;
    transform-origin: center;
  }
}

.dotButton {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.3125rem;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}